import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { Grid, Typography , Button} from '@mui/material';
import IndividualFormWithTitle from '../component/IndiviualFormWithTitle';
import Container from '@mui/material/Container';
import { useFormContext } from '../contexts/FormContext';
import Header from '../component/Header';
// import { collection, doc, setDoc } from 'firebase/firestore';
import { getDoc, doc, setDoc } from 'firebase/firestore';
// import { collection, where, getDocs, query } from 'firebase/firestore';
import { firestore, firebaseAuth } from '../firebase';
import { signOut as FBSignout } from 'firebase/auth';
import { useSession } from '../contexts/SessionContext';
import Popup from '../component/Popup';
import { useNavigate } from 'react-router-dom';

// import BasicAlerts from '../component/BasicAlerts';
// import Alert from '@mui/material/Alert';
// import AlertTitle from '@mui/material/AlertTitle';
// import Snackbar from '@mui/material/Snackbar';


const SurveyForm = (props) => {

    // Sample data
    const { user, signOut } = useSession();
    const navigate = useNavigate();

    const [tempUserID, setTempUserID] = useState(2)
    const [isInvalid, setValidation] = useState(false)
    const [canDelete, setCanDelete] = useState(false)
    const [wardNumber, setWardNumber] = useState('')
    const [houseNumber, setHouseNumber] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [userSuggestion, setUserSuggestion] = useState('')
    // const [errorMsg, setErrorMsg] = useState({type: "error", title: "", message: "", show: false});
    const [submitted, setSubmitted] = useState(false)

    const { state, updateField, addMember, removeMember, initiateMembers } = useFormContext();
    

    // const fetchDataWithWhereClause = async () => {
    //     const q = query(collection(firestore, "UserDoorNumber"), where("door_number", "==", "123"));
    //     try {
    //         const querySnapshot = await getDocs(q);
    //         const details = querySnapshot.docs.map(doc => doc.data());
    //         console.log(details);
    //     } catch (error) {
    //         console.error('Error fetching details:', error);
    //     throw error;
    //     }
    //     console.log(q);
    // };

    // fetchDataWithWhereClause();
    // Example usage
    // const collectionName = 'UsedHouseNumber';
    // const documentId = 'your_document_id';
    
    const actionRemove = (idToRemove) => {
        removeMember(idToRemove);
    }

    const addForm = () => {
        if(!isInvalid){
            setTempUserID(tempUserID+1)
            addMember(tempUserID);
        }
    };

    const onFormChange = (memberId, field, value) => {
        updateField(memberId, field, value)
    }

    const onWardNumberChange = (e) => {
        setWardNumber(e.target.value)
    }

    const onHouseNumberChange = (e) => {
        setHouseNumber(e.target.value)
    }

    const onPhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value)
    }

    const onSuggestionChange = (e) => {
        setUserSuggestion(e.target.value)
    }

    const submitForm = () => {

        const tempDetails = {
            wardNumber: wardNumber,
            houseNumber: houseNumber,
            phoneNumber: phoneNumber,
            members: state.familyMembers,
            suggestion: userSuggestion
        }

        if(!isInvalid){
            if(wardNumber&&houseNumber&&phoneNumber){
                const userRef = doc(firestore, 'UserInformation', user.uid);
                // Use setDoc to create or update the document
                setDoc(userRef, tempDetails)
                .then(() => {
                    setSubmitted(true)
                    // setErrorMsg({...errorMsg, title: "Success", type: "success", message: "Details submitted successfully!", show: true});
                })
                .catch((error) => {
                    console.error('Error writing or updating document:', error);
                });
            }
        }

    }

    function handleSuccessAction() {
        FBSignout(firebaseAuth)
        signOut()
        navigate('/login');

    }

    // Function to read a document by ID
    async function readDocumentById() {
        try {
            const docRef = doc(firestore, 'UserInformation', user.uid);
            const docSnapshot = await getDoc(docRef)

            if (docSnapshot.exists()) {
                // Document exists, you can access its data
                const data = docSnapshot.data();
                setWardNumber(data.wardNumber);
                setHouseNumber(data.houseNumber);
                setUserSuggestion(data.suggestion);
                setPhoneNumber(data.phoneNumber);

                let member_count = data.members.length
                if(member_count > 0){

                    const sortedArray = data.members.slice().sort((a, b) => a.index - b.index);
                    console.log(sortedArray);
                    initiateMembers(sortedArray);
                    setTempUserID(member_count+1);
                    
                // console.log('Document data:', data);
                }
                
            } else {
                console.log('Document does not exist');
            }
        } catch (error) {
            console.error('Error reading document:', error);
        }
    }

    useEffect(() => {
        
        readDocumentById();

        return () => {
        };
    }, []);

    useEffect(() => {
        setValidation(false)
        state.familyMembers.forEach((member) => {
            if(!member.is_valid){
                setValidation(true)
            }
        });
        if(state.familyMembers.length > 1){
            setCanDelete(true)
        }else{
            setCanDelete(false)
        }
    }, [state.familyMembers]);
    
    return (
        <Grid>
            <Header/>
            <Container fixed className='Gap'>
                <Typography 
                gutterBottom={true}
                variant="h3"
                align='center'>
                    Jama-ath Education Survey
                </Typography>
                <Typography 
                variant="body1"
                className='Description'>
                    The provider pattern is used by the react-intl library to set the scope of the internationalization context into a tree of components. This will wrap the application’s root component and the whole application will be configured within the internationalization context. Then we’ll declare a constant variable to navigate the language using the navigator.language
                </Typography>
                <Grid 
                container
                className='Form' 
                direction="column">
                    <Grid item xs={12} md={12} lg={12}>
                        <Typography 
                        gutterBottom={true}
                        variant="h5"
                        align='center'>
                            Basic Information
                        </Typography>
                    </Grid>
                    <Grid 
                    container
                    spacing={2}
                    className='GridPadding'
                    direction="row">
                    <Grid item xs={12} md={6} lg={6}>
                        <TextField 
                        id="ward-number" 
                        label="Ward number" 
                        variant="outlined" 
                        className='FormTextField'
                        value={wardNumber}
                        onChange={onWardNumberChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <TextField 
                        id="panchayath-house-number" 
                        label="Panchayath house no." 
                        variant="outlined" 
                        className='FormTextField'
                        value={houseNumber}
                        onChange={onHouseNumberChange}
                        />
                    </Grid>
                    </Grid>
                    <Grid 
                    container
                    spacing={2}
                    className='GridPadding'
                    direction="row">
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField 
                            id="head-of-family-phone" 
                            label="Phone Number" 
                            variant="outlined" 
                            className='FormTextField'
                            value={phoneNumber}
                            onChange={onPhoneNumberChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid 
                container
                className='Form' 
                direction="column">
                    <Grid 
                    container
                    spacing={2}
                    className='GridPadding'
                    direction="row">
                        <Grid item xs={12} md={12} lg={12}>
                            <Typography 
                                gutterBottom={true}
                                variant="h5"
                                align='center'>
                                Family Details
                            </Typography>
                        </Grid>
                    </Grid>
                    {
                        state.familyMembers.map((data, index) => (
                            <IndividualFormWithTitle 
                                index={index}
                                key={index}
                                id={data.id}
                                data={data}
                                onRemove={actionRemove}
                                canDelete={canDelete}
                                onChange={(input, value, memberID)=>{
                                    onFormChange(memberID, input, value);
                                }}
                            />
                        ))
                    }
                    <Grid item xs={12} md={12} lg={12} className='PadLeftRight10'>
                        <Button  className='Width100Per' disabled={isInvalid} onClick={addForm}>Add Member(+)</Button>    
                    </Grid>
                </Grid>
                <Grid 
                container
                className='Form' 
                direction="column">
                    <Grid item xs={12} md={12} lg={12}>
                    <TextField
                        multiline
                        rows={4}  // Adjust the number of rows as needed
                        fullWidth  // This makes the input span 100% of the width
                        label="Any suggestion for better education?"
                        variant="outlined"
                        onChange={onSuggestionChange}
                        value={userSuggestion}
                    />
                    </Grid>
                </Grid>
                <Grid 
                container 
                direction="column">
                    <Grid item xs={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', paddingTop: '20px', paddingBottom: '20px' }}>
                        <Button variant="contained" disabled={isInvalid} onClick={submitForm} style={{ paddingRight: '20vh', paddingLeft: '20vh' }}>Submit </Button>
                    </Grid>
                </Grid>
            </Container>
            <Popup 
                dialogStatus={submitted}
                onClose={handleSuccessAction}
            />
            {/* <Snackbar
                open={errorMsg.show}
                autoHideDuration={6000} // Adjust the duration as needed
                style={{width: "100%"}}>
                <Alert severity={errorMsg.type}>
                    <AlertTitle>{errorMsg.title}</AlertTitle>
                    {errorMsg.message}
                </Alert>
            </Snackbar> */}
        </Grid>
    );
};
  
export default SurveyForm;
  