import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import BasicSelect from './BasicSelect';
import dayjs from 'dayjs';

const IndividualForm = (props) => {

    const {data} = props

    const handleChange = (input, value) => {
      props.onChange(input, value);
    }

    const handleMaritalStatusVisibility = (event) => {
      handleChange("occupation", event.value);
    }

    return (
      <Grid 
      container
      direction="column">
        <Grid 
        container
        spacing={2}
        className='GridPadding'
        direction="row">
          <Grid item xs={12} md={6} lg={6}>
            <TextField 
            id="person-name" 
            label="Name" 
            variant="outlined" 
            className='FormTextField'
            value={data.name}
            onChange={(e) => {
              handleChange("name", e.target.value)
            }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker 
                  className='Width100Per' 
                  label="Date of Birth"
                  value={data.dob? dayjs(data.dob):null}
                  onChange={(e) => {
                    let selectedDate = e.$d;

                    if (!isNaN(selectedDate)){
                      let dd = String(selectedDate.getDate()).padStart(2, '0');
                      let mm = String(selectedDate.getMonth() + 1).padStart(2, '0'); // January is 0!
                      let yyyy = selectedDate.getFullYear();

                      let formattedDate = yyyy + '-' + mm + '-' + dd;
                      handleChange("dob", formattedDate)
                    }
                  }}
                  format="DD/MM/YYYY"
                />
            </LocalizationProvider>
          </Grid>
        </Grid> 
        <Grid 
          container
          spacing={2}
          className='GridPadding'
          direction="row">
          <Grid item xs={12} md={6} lg={6}>
            <BasicSelect 
                label="Gender"
                id="demo-simple-select"
                selectedOption={data.gender}
                options={[{label: 'Male', value: 'male'}, {label: 'Female', value: 'female'}]}
                onSelect={(e) => {
                  handleChange("gender", e.value)
                }}
              />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TextField 
              id="person-qualification" 
              label="Qualification" 
              variant="outlined" 
              className='FormTextField'
              value={data.qualification}
              onChange={(e) => {
                handleChange("qualification", e.target.value)
              }}
            />
          </Grid>
        </Grid>
        <Grid 
          container
          spacing={2}
          className='GridPadding'
          direction="row">
          <Grid item xs={12} md={6} lg={6}>
            <TextField 
              id="person-school-college" 
              label="Name of School/College" 
              variant="outlined" 
              className='FormTextField'
              value={data.name_of_school}
              onChange={(e) => {
                handleChange("name_of_school", e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <BasicSelect 
              label="Residing"
              id="demo-simple-select"
              options={[{label: 'Local', value: 'local'}, {label: 'Abroad', value: 'abroad'}]}
              selectedOption={data.residency}
              onSelect={(e) => {
                handleChange("residency", e.value)
              }}
            />
          </Grid>
        </Grid> 
        <Grid 
          container
          spacing={2}
          className='GridPadding'
          direction="row">
            <Grid item xs={12} md={6} lg={6}>
              <BasicSelect 
                label="Occupation"
                id="demo-simple-select"
                options={[{label: 'Student', value: 'student'}, {label: 'Business', value: 'business'}, {label: 'Employed', value: 'employed'}, {label: 'Other', value: 'other'}]}
                selectedOption={data.occupation}
                onSelect={handleMaritalStatusVisibility}
              />
            </Grid>
            { props.index != 0 &&
              <Grid item xs={12} md={6} lg={6}>
                <BasicSelect 
                  label="Relation with head of family"
                  id="demo-simple-select"
                  options={[{label: 'Mother', value: 'mother'}, {label: 'Father', value: 'father'}, {label: 'Brother', value: 'brother'}, {label: 'Sister', value: 'sister'}, {label: 'Head of the Family', value: 'hof'}]}
                  selectedOption={data.head_of_family}
                  onSelect={(e) => {
                    handleChange("head_of_family", e.value)
                  }}
                />
              </Grid>
            }
            {data.occupation !== 'student' && 
              <Grid item xs={12} md={6} lg={6}>
                <BasicSelect 
                  label="Marital Status"
                  id="demo-simple-select"
                  options={[{label: 'Unmarried', value: 'unmarried'}, {label: 'Married', value: 'married'}]}
                  selectedOption={data.marital_status}
                  onSelect={(e) => {
                    handleChange("marital_status", e.value)
                  }}
                />
              </Grid>
            }
        </Grid> 
      </Grid> 
    );
};
  
export default IndividualForm;
  