import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import logo from '../assets/images/SaqafSchoolLogo.png'
import { useSession } from '../contexts/SessionContext';
import { signOut as FBSignout } from 'firebase/auth';
import { firebaseAuth } from '../firebase';
import { useNavigate } from 'react-router-dom';

const settings = ['Logout'];

function Header() {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();

  const { user, signOut } = useSession()

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(false)
  }

  const logout = () => {
    FBSignout(firebaseAuth);
    signOut();
    navigate('/login');
  };

  return (
    <AppBar position="static" style={{background: "#FFF"}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img src={logo} style={{width: 200}} />
          <Box sx={{ flexGrow: 0 }} style={{position: "absolute", right: 0}}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  sx={{
                    width: theme => theme.spacing(6), // Adjust the size as needed
                    height: theme => theme.spacing(6),
                    backgroundColor: (theme) => theme.palette.primary.main,
                    border: (theme) => `3px solid ${theme.palette.secondary.main}`,
                    textTransform: 'uppercase',
                  }}
                >
                  {user.displayName.charAt(0)}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem key={"Logout"} onClick={logout}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;