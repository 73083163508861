// FirebaseContext.js
import React, { createContext, useEffect } from 'react';
import { firebaseAuth } from '../firebase';
import { GoogleAuthProvider, onAuthStateChanged, setPersistence, browserSessionPersistence } from 'firebase/auth';
import { useSession } from './SessionContext';

// Set session persistence
setPersistence(firebaseAuth, browserSessionPersistence);

const FirebaseContext = createContext(null);

const FirebaseProvider = ({ children }) => {

  const { signIn, signOut } = useSession();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      // This function will be called whenever the user's authentication state changes
      if(user) {
        signIn(user)
      } else {
        signOut();
      }

    });

    // Cleanup the listener on component unmount
    return () => unsubscribe();
  }, [signIn, signOut]);

  return (
    <FirebaseContext.Provider value={{ auth: firebaseAuth, GoogleAuthProvider }} >
      {children}
    </FirebaseContext.Provider>
  );
};

export { FirebaseContext, FirebaseProvider };
