// GoogleSignInButton.js
import React, { useContext } from 'react';
import { signInWithPopup } from 'firebase/auth';
import { FirebaseContext } from '../contexts/FirebaseContext';
import { useSession } from '../contexts/SessionContext';
import Button from '@mui/material/Button';

const GoogleSignInButton = () => {
  const { auth, GoogleAuthProvider } = useContext(FirebaseContext);
  const { signIn } = useSession();

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();

    try {
      await signInWithPopup(auth, provider)
      .then((result) => {
        signIn(result.user);
        // IdP data available in result.additionalUserInfo.profile.

      })
    } catch (error) {
      console.error('Google Sign-In Error:', error);
    }
  };

  return (
    <Button
      variant="contained"
      fullWidth
      sx={{ marginTop: 2 }}
      onClick={signInWithGoogle}
      >
          Login with Google
    </Button>
  );
};

export default GoogleSignInButton;
