// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#043471', // Change to your desired primary color
    },
    secondary: {
      main: '#C8A43E', // Change to your desired secondary color
    },
  },
  typography: {
    fontFamily: 'AlbertSans, sans-serif',
  },
});

export default theme;