import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CheckIcon from '@mui/icons-material/Check';

const Popup = ({dialogStatus, onClose}) => {
//   const [open, setOpen] = useState(false);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={dialogStatus} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle style={{ textAlign: 'center' }}>
          <div style={{ border: '2px solid #b9e88b', borderRadius: '50%', padding: '5%', display: 'inline-block', width: '2em', height:'2em' }}>
            <CheckIcon style={{ color: '#4caf50', fontSize: '2em' }} />
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: 'center' }}>
            Thank you for submitting your form!
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button onClick={handleClose} variant="contained" style={{ backgroundColor: '#4caf50', color: '#fff' }}>
            Close
          </Button>
        </DialogActions>
    </Dialog>
  );
};

export default Popup;
