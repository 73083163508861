import * as React from 'react';
import Box from '@mui/material/Box';
import GoogleSignInButton from '../component/GoogleSignInButton';

const Login = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          width: '90%', // Responsive width
          maxWidth: 400, // Maximum width
          height: 'auto',
          padding: 3,
          boxShadow: 5, // Adds a shadow
          textAlign: 'center',
        }}
      >
        <p>Welcome to Saqaf school</p>
        <GoogleSignInButton />
        {/* Google Login Button */}
        
      </Box>
    </Box>
  );
};

export default Login;
