// src/FormContext.js
import React, { createContext, useReducer, useContext } from 'react';
import { useSession } from './SessionContext';

const initialState = {
  familyMembers: [
	{
		id: 1,
		name: "",
		dob: "",
		gender: "",
		qualification: "",
		name_of_school: "",
		residency: "",
		occupation: "",
		marital_status: "",
		head_of_family: "",
		is_valid: false,
		error_message: "",
		index: 0
	},
  ],
};

const FormContext = createContext();

const formReducer = (state, action) => {
	switch (action.type) {
		case 'UPDATE_FIELD':
			const { memberIdUpdate, field, value } = action;
			const updatedMembersDetail = state.familyMembers.map((member) => {
					let tempMember = member
					let is_valid = true
					let errors = []
					let error_message = ""
					if( tempMember.id === memberIdUpdate ){

						tempMember = { ...tempMember, [field]: value }

						if(!tempMember.name){
							is_valid = false
							errors.push("name")
						}

						if(!tempMember.dob){
							is_valid = false
							errors.push("Date of Birth")
						}

						if(!tempMember.gender){
							is_valid = false
							errors.push("Gender")
						}

						if(!tempMember.qualification){
							is_valid = false
							errors.push("Qualification")
						}

						if(!tempMember.name_of_school){
							is_valid = false
							errors.push("Name of school")
						}

						if(!tempMember.residency){
							is_valid = false
							errors.push("Residency")
						}

						if(!tempMember.occupation){
							is_valid = false
							errors.push("Occupation")
						}

						if(tempMember.occupation != 'student' && tempMember.occupation){
							if(!tempMember.marital_status){
								is_valid = false
								errors.push("Marital status")
							}
						}

						if(errors.length > 0){
							if(errors.length > 1){
								error_message = "Enter valid "+(errors.join(", "))+" details"
							}else{
								error_message = "Enter valid "+(errors.join(", "))+" detail"
							}
							
						}

						tempMember = { ...tempMember, is_valid: is_valid, error_message: error_message }
						
					}
					return tempMember
				}
			);
			return {
				...state,
				familyMembers: updatedMembersDetail,
			};
		case 'ADD_MEMBER':
			const { memberId } = action;
			return {
				...state,
				familyMembers: [
				...state.familyMembers,
				{
					id: memberId,
					name: "",
					dob: "",
					gender: "",
					qualification: "",
					name_of_school: "",
					residency: "",
					occupation: "",
					marital_status: "",
					head_of_family: "",
					is_valid: false,
					error_message: "",
					index: 1
				},],
			};
		case 'INITIATE_MEMBERS':
			const { data } = action;
			return {
				...state,
				familyMembers: data,
			};
		case 'REMOVE_MEMBER':
			const updatedMembers = state.familyMembers.filter(
				(member) => member.id !== action.memberId
			);
			return { 
				...state,
				familyMembers: updatedMembers,
			};
		default:
			return state;
	}
};

export const FormProvider = ({ children }) => {
	const [state, dispatch] = useReducer(formReducer, initialState);

	const updateField = (memberId, field, value) => {
		dispatch({ type: 'UPDATE_FIELD', memberIdUpdate: memberId, field, value });
	};

	const addMember = (memberId) => {
		dispatch({ type: 'ADD_MEMBER', memberId });
	};

	const removeMember = (memberId) => {
		dispatch({ type: 'REMOVE_MEMBER', memberId });
	};

	const initiateMembers = (data) => {
		dispatch({type: 'INITIATE_MEMBERS', data });
	}

	return (
		<FormContext.Provider value={{ state, updateField, addMember, removeMember, initiateMembers }}>
			{children}
		</FormContext.Provider>
	);
};

export const useFormContext = () => {
	const context = useContext(FormContext);
	if (!context) {
		throw new Error('useFormContext must be used within a FormProvider');
	}
	return context;
};
