import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function BasicSelect(props) {

  const handleChange = (event) => {
    props.onSelect(event.target);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id={`${props.id}-select`}>{props.label}</InputLabel>
        <Select
          labelId={`${props.id}-select`}
          id={`member-select-${props.id}`}
          value={props.selectedOption}
          label={props.label}
          onChange={handleChange}
        >
          { props.options.map((item, index) => {
              return <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
            })
          }
        </Select>
      </FormControl>
    </Box>
  );
}