// PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSession } from '../contexts/SessionContext';

const PrivateRoute = ({ children }) => {
    const { user } = useSession();
    return user ? children : <Navigate to="/login" />;    
};
  
  export default PrivateRoute;
