
import { Button, Grid } from '@mui/material';
import { Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import IndividualForm from './IndividualForm';
import { useMediaQuery } from '@mui/material';

function IndividualFormWithTitle(props){

    const deleteAction = (id) => {
      props.onRemove(id)
    }

    const onChange = (input, value, memberID) => {
        props.onChange(input, value, memberID)
    }

    return(
      <Grid 
        container
        className='Form' 
        direction="row">
          <Grid item xs={10} md={10} lg={10}>
            {
                props.index >= 1 &&
                <Typography 
                    gutterBottom={true}
                    variant="h5"
                    align='left'>
                        Member #{props.index}
                </Typography>
            }
            {
                props.index === 0 &&
                <Typography 
                    gutterBottom={true}
                    variant="h5"
                    align='left'>
                        Head of family detail
                </Typography>
            }
          </Grid>
          <Grid item xs={2} md={2} lg={2}>
            <IconButton className='floatRight' aria-label="delete" disabled={!props.canDelete || props.index === 0} onClick={() => deleteAction(props.id)}>
                <DeleteIcon />
            </IconButton>
            
          </Grid>
          <IndividualForm 
            data={props.data}
            index={props.index}
            onChange={(input, value) => {
                onChange(input, value, props.id)
            }}
          />
          <Grid className='PadTopBtm20' item xs={12} md={12} lg={12}>
            <Typography 
              gutterBottom={true}
              variant="string"
              align='left'
              className='ErrorMessage'>
                {props.data.error_message}
            </Typography>
        </Grid>
      </Grid>
    )
}

export default IndividualFormWithTitle;
