import './assets/css/App.css';
import './assets/css/font.css'
import './assets/css/survey_form.css'
import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import SurveyForm from './screen/SurveyForm';
import { FormProvider } from './contexts/FormContext';
import { FirebaseProvider } from './contexts/FirebaseContext';
import { SessionProvider, useSession } from './contexts/SessionContext';
import Login from './screen/Login';
import PrivateRoute from './routes/PrivateRoute';

function App() { 
  return (
    // <FormProvider>
    //   <SurveyForm />
    // </FormProvider>
    <Router>
      <SessionProvider>
        <FirebaseProvider>
          <FormProvider>
            <Fragment>
              <Routes>
                <Route exact path='/' element={<RedirectFromRoot/>}/>
                <Route
                    path="/jama-ath-education-survey"
                    element={
                      <PrivateRoute>
                        <SurveyForm/>
                      </PrivateRoute>
                    }
                />
                <Route exact path='/login' element={<LoginOrSurveyForm/>}/>
              </Routes>
            </Fragment>
          </FormProvider>
        </FirebaseProvider>
      </SessionProvider>
    </Router>
  );

};

const LoginOrSurveyForm = () => {
  const { user } = useSession();
  // Redirect to /surveyform if the user is already logged in
  if (user) {
    return <Navigate to="/jama-ath-education-survey" />;
  }

  // Otherwise, show the login page
  return <Login />;
};
const RedirectFromRoot = () => {
  const { user } = useSession();

  if(user) {
    return <Navigate to="/jama-ath-education-survey" />
  }

  return <Navigate to="/login" />

}

export default App;
