// firebaseConfig.js
const firebaseConfig = {
    apiKey: "AIzaSyDDfvSoymFllgNWLEdUEE27JJiYiJioRYQ",
    authDomain: "saqafschool-41c37.firebaseapp.com",
    projectId: "saqafschool-41c37",
    storageBucket: "saqafschool-41c37.appspot.com",
    messagingSenderId: "166898201225",
    appId: "1:166898201225:web:f669ae82b9a075869acc58"
  };
  
  export default firebaseConfig;
  